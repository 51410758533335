/* eslint-disable no-unused-vars */
import { SyntheticEvent } from "react";
import { Box, Snackbar as MuiSnackbar, SnackbarCloseReason, SnackbarProps } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@redux/reducers";
import { setCloseSnackbar } from "@redux/slices/rootSnackbar";
import color from "@definitions/mui/color";
import Text from "../Text";
import useStyles from "./styles";
import type { TAnchor } from "./type";

interface ISnackbarProps extends Omit<SnackbarProps, "onClose"> {
  open?: boolean;
  message?: string;
  variant?: string;
  duration?: number;
  action?: any;
  anchor?: TAnchor;
  onClose?: (
    event: Event | SyntheticEvent<any, Event> | Event,
    reason: SnackbarCloseReason,
  ) => void;
}

const Snackbar: React.FC<ISnackbarProps> = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { snackbar } = useSelector((state: RootState) => ({
    snackbar: state.rootReducer.rootSnackbar,
  }));
  const { open, message, variant, duration = 4000, action, anchor = "top-center" } = snackbar;
  const _anchor = anchor?.split("-");

  const _renderBackground = () => {
    let bground;

    switch (variant) {
      case "success": {
        bground = color.gradient.success;
        break;
      }
      case "failed": {
        bground = color.gradient.failed;
        break;
      }
      case "info": {
        bground = color.gradient.info;
        break;
      }
      case "warning": {
        bground = color.gradient.warning;
        break;
      }
    }

    return bground;
  };

  const onCloseSnackbar = (
    event: Event | SyntheticEvent<any, Event> | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setCloseSnackbar());
  };

  const _renderActionButton = () => {
    if (action) {
      <>
        {action?.map(({ label = "", onClick }: any, key: number) => (
          <Box key={key}>
            <Text color="white" clickable onClick={onClick}>
              {label}
            </Text>
          </Box>
        ))}
      </>;
    }
    return;
  };

  return (
    <MuiSnackbar
      autoHideDuration={duration}
      anchorOrigin={{
        vertical: _anchor[0],
        horizontal: _anchor[1],
      }}
      open={open}
      onClose={onCloseSnackbar}
    >
      <Box
        className={classes.root}
        sx={{
          background: _renderBackground(),
        }}
      >
        <Text color="white">{message}</Text>
        {_renderActionButton()}
      </Box>
    </MuiSnackbar>
  );
};

export default Snackbar;
