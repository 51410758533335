import color from "@definitions/mui/color";
import { fontFamSecondary } from "@definitions/mui/custom";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  "root": {
    borderRadius: 9,
    color: color.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: "18px",
    fontFamily: fontFamSecondary,
    minWidth: 328,
    padding: '12px 20px',
  },
}));

export default useStyles;
